<script setup lang="ts">
import type { Component } from "vue";
import Order from "../icons/Order.vue";

type OrderOption = {
  label: string;
  value: string;
};

withDefaults(
  defineProps<{
    baseKey: string;
    icon?: Component;
    title?: string;
    options?: OrderOption[];
  }>(),
  {
    icon: Order,
    title: "Ordina",
    options: () => [
      { value: "desc", label: "Dal più recente" },
      { value: "asc", label: "Dal meno recente" },
    ],
  }
);

const model = defineModel<string>({ default: "desc" });
</script>

<template>
  <FrontPrimaryCard :icon="icon" :title="title">
    <RadioGroup v-model="model">
      <div class="flex items-center space-x-2" v-for="option in options" :key="`${baseKey}-${option.value}`">
        <RadioGroupItem :id="`${baseKey}-${option.value}`" :value="option.value" />
        <label :for="`${baseKey}-${option.value}`">{{ option.label }}</label>
      </div>
    </RadioGroup>
  </FrontPrimaryCard>
</template>
